
.medicina-toggle{
    width: 100px;
    height: 100px;
    background-image: url('../../../img/medicina.png') ;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
    transition: opacity 0.3s, visibility 0.3s, duration 0.5s, 0.5s;
    
  }
  
  .medicina-toggle.open{
    width: 80px;
    height: 80px;
    background-image: url('../../../img/medicina.png') ;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
  
  }



.funcaog-toggle{
    width: 100px;
    height: 100px;
    background-image: url('../../../img/doc.png') ;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
    transition: opacity 0.3s, visibility 0.3s, duration 0.5s, 0.5s;
    
  }
  
  .funcaog-toggle.open{
    width: 80px;
    height: 80px;
    background-image: url('../../../img/doc.png') ;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
  
  }



  .suptbes-toggle{
    width: 100px;
    height: 100px;
    background-image: url('../../../img/cobranca.png') ;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
    transition: opacity 0.3s, visibility 0.3s, duration 0.5s, 0.5s;
    
  }
  
  .suptbes-toggle.open{
    width: 80px;
    height: 80px;
    background-image: url('../../../img/cobranca.png') ;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
  
  }
