h1{
    display: flex;
    justify-content: flex-start;
  }

  .ger-toggle{
    width: 100px;
    height: 100px;
    background-image: url('../../../img/ger.png') ;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
    transition: opacity 0.3s, visibility 0.3s, duration 0.5s, 0.5s;
    
  }
  
  .ger-toggle.open{
    width: 80px;
    height: 80px;
    background-image: url('../../../img/ger.png') ;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
  
  }