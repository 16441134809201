.styled-table {
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 800px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}


.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}


.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

h1{
    margin-left: 0%;
}


@media (max-width: 800px) {
    .styled-table {
        min-width: 6%;
        margin: 10px 0;
        font-size: 0.1em;
    }

    
    .styled-table th,
.styled-table td {
    padding: 2px 5px;
}


    
h1{
    margin-left: 0%;
    font-size: 20px;
}

}

