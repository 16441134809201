.doc-toggle{
    width: 100px;
    height: 100px;
    background-image: url('../../../img/documento.png') ;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
    transition: opacity 0.3s, visibility 0.3s, duration 0.5s, 0.5s;
    
  }
  
  .doc-toggle.open{
    width: 80px;
    height: 80px;
    background-image: url('../../../img/documento.png') ;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
  
  }

  .arq-toggle{
    width: 100px;
    height: 100px;
    background-image: url('../../../img/arquivamento.png') ;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
    transition: opacity 0.3s, visibility 0.3s, duration 0.5s, 0.5s;
    
  }
  
  .arq-toggle.open{
    width: 80px;
    height: 80px;
    background-image: url('../../../img/arquivamento.png') ;
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: center center;
      background-color: rgba(255, 255, 255, 0);
    border: none;
  
  }