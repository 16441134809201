
@media (max-width: 1000px) {
  .buttons{
    display: flex;
    flex-direction: column;
  }

  .wholescreen {
    justify-content: flex-start;
    padding: 10px;
  }

  /* .mainsquare {
    width: 80%;
    height: auto;
  } */

  .grid {
    width: 100%;
    height: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }

  .grid-item {
    font-size: medium;
  }

  .meus {
    width: 100%;
    height: auto;
  }

  .img {
    height: 80px;
    width: 80px;
    background-size: 60px;
  }
  
}

.wholescreen {
    background-color: gray;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../img/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0);
}

.mainsquare {
    width: 900px;
    height: 700px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    -webkit-box-shadow: -1px 7px 58px 4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 7px 58px 4px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 7px 58px 4px rgba(0, 0, 0, 0.75);
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 5px;
    width: 800px;
    height: 400px;
    
  }

  .grid-item {
    background-color: lightgray;
    /* border: 1px solid gray; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: -5px 3px 6px black;
    font-size: larger;
    border-radius: 10px;
  }

  .meus {
    background-color: #aaa;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: -5px 3px 6px black;
    font-size: larger;
    width: 800px;
    height: 80px;
    border-radius: 10px;
  }

  .img{
    height: 100px;
    width: 100px;
    background-image: url('../../img/logocasal60.png') ;
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: center;
    margin-bottom: -10px;
  }


  .bgfitter {
   
}


.App {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.accordion {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.accordion-item {
  margin: 10px 0;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.accordion-content {
  padding: 10px;
}

.field-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.field-item input {
  padding: 5px;
  margin-right: 10px;
  flex: 1;
}

.field-item button {
  padding: 5px 10px;
}